import React, { useEffect, useState } from "react";
import Navigationbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./reports.scss";
import { quaterlyreportsref } from "../../../Firebase/Firebase";
import { getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const QuaterlyReport = () => {
  // Get Reports Start

  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      const _data = await getDocs(quaterlyreportsref);
      _data.forEach((element) => {
        setData((prv) => [...prv, { data: element.data(), id: element.id }]);
      });
    }
    getData();
  }, []);

  // Get reports en

  return (
    <>
      <div>
        <div className="Section">
          <Navigationbar />
          <div className="center reportscontainer">
            <div className="title">
              <h3>Quaterly Reports</h3>
            </div>
            <div >
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {data
                    .sort((a, b) => (a.data.date < b.data.date ? 1 : -1))
                    .map((e, i) => (
                      <Grid item xs={6} sm={4} md={4} key={i}>
                        <Item>
                          <h5>{e.data.year}</h5>
                          <h5>{e.data.title}</h5>
                          {/* <p>hello this is news </p> */}
                          <button className="button">
                            {" "}
                            <Link to={e.data.file}>View Report</Link>
                          </button>
                        </Item>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </div>
          </div>
        </div>
        <div className="Section footerfullscreeen">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default QuaterlyReport;
