import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import PreviewIcon from '@mui/icons-material/Preview';
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import Navmenu from "../Components/Navmenu"
import { contactusref } from "../../Firebase/Firebase";
import { doc, getDocs, updateDoc } from "firebase/firestore";
import CloseIcon from '@mui/icons-material/Close';
import { Form, Col, Row } from "react-bootstrap";


const navdata = Navmenu()

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// For Dialog Box to add Data

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// End ffor dialog box to add data

function Contactus() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [viewdata, setViewdata]= useState("")
  const Navigate = useNavigate()


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //   For Dialog Box to add Data
  const [openform, setOpenform] = React.useState(false);


  const handleOpenform = (data) => {
    setOpenform(true);
  
   
    
  };

  const handleCloseform = () => {
    setOpenform(false);
    Navigate("/admin/contactus")
  };

  // End dialog box to add data

  const [data, setData] = useState([])
  useEffect(() => {
    async function getData() {
      const _data = await getDocs(contactusref);
      _data.forEach((element) => {
        setData((prv) => [...prv, { data: element.data(), id: element.id }]);
      });
    }
    getData();
  }, []);

const Viewdata = async(data) =>{
  setViewdata(data.data)
  handleOpenform()
  

if (data.data.status != "read") {
  await updateDoc(doc(contactusref, data.id), {
    status: "read",
  });
}
 

  

}



  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Message
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" noWrap component="div">
            Shikhar Power
          </Typography>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navdata.map((text, index) => (
            <Link to={text.link} className="menubuttonlink">
              <ListItem
                key={text.name}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  className="menubutton"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemText
                    primary={text.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
      </Drawer>
      {/* Content Start */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />


        {/* Data Grid Started 
        here we will show Data
        */}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.N.</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Phone</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {
                  data.sort((a,b)=>a.data.date < b.data.date ? 1 : -1).map((e , i)=>(
                    <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                
              >
               
                <TableCell component="th" scope="row">
                 {i+1}
                </TableCell>
                <TableCell align="center">{e.data.name}</TableCell>
                <TableCell align="center">{e.data.phone}</TableCell>
                <TableCell align="center">{e.data.email}</TableCell>
                <TableCell align="center">{e.data.date}</TableCell>
                <TableCell align="center">{e.data.status}</TableCell>
                <TableCell align="center">
                  <Button variant="text" onClick={()=>Viewdata(e)}>
                    <PreviewIcon/>
                  </Button>
                </TableCell>
              </TableRow>

                  ))
                }
              
            </TableBody>
          </Table>
        </TableContainer>

        {/* End Table Data */}

        {/* For Dialog box to add data */}
        <Dialog
          fullScreen
          open={openform}
          onClose={handleCloseform}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar sx={{ pl: 2, flex: 1 }}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
               Message Details
              </Typography>
              <div onClick={handleCloseform}>
              <CloseIcon style={{fontSize:'2em', cursor:'pointer'}}/>
              </div>
            </Toolbar>
          </AppBar>
          <Stack
            direction="Column"
            justifyContent="center"
            alignItems="center"
            marginTop={10}
            spacing={5}
          >
            <Stack width={800} spacing={0}>
              



            <Form style={{ width: '100%', flex: '1' }} >
                      <Form.Group size="sm" as={Row} controlId="formPlaintextFname">
                        <Form.Label column sm="3">
                          <h4>

                            Name:
                          </h4>
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="text" placeholder="No Name"
                          value={viewdata.name}
                          readOnly

                          />
                        </Col>
                      </Form.Group>
                      <Form.Group size="sm" as={Row} controlId="formPlaintextFname">
                        <Form.Label column sm="3">
                          <h4>

                            Phone:
                          </h4>
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="text" placeholder="No Phone"
                          value={viewdata.phone}
                          readOnly

                          />
                        </Col>
                      </Form.Group>
                      <Form.Group size="sm" as={Row} controlId="formPlaintextFname">
                        <Form.Label column sm="3">
                          <h4>

                            Email:
                          </h4>
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="text" placeholder="No Email"
                          value={viewdata.email}
                          readOnly

                          />
                        </Col>
                      </Form.Group>
                      <Form.Group size="sm" as={Row} controlId="exampleForm.ControlTextarea1">
                        <Form.Label column sm="3">
                          <h4>Message:</h4>
                          </Form.Label>
                          <Col sm="8">
                        <Form.Control as="textarea" rows="15"  placeholder="No Message"
                          value={viewdata.message}
                          readOnly
                        
                        />
                        </Col>

                      </Form.Group>
                      
                    
                    </Form>






              {/* <span style={{display:'flex', alignItems:'center'}}>
                <h4 style={{width:'100px', textAlign:"right", padding:'4px'}}>Name: </h4>
                <h4 >{viewdata.name}</h4>
                
              </span>
              <span style={{display:'flex', alignItems:'center'}}>
                <h4 style={{width:'100px', textAlign:"right", padding:'4px'}}>Phone: </h4>
                <h4 >{viewdata.phone}</h4>
                
              </span>
             
              <span style={{display:'flex', alignItems:'center'}}>
                <h4 style={{width:'100px', textAlign:"right", padding:'4px'}}>Email: </h4>
                <h4 >{viewdata.email}</h4>
                
              </span>
              <span style={{display:'flex', alignItems:'center'}}>
                <h4 style={{width:'100px', textAlign:"right", padding:'4px'}}>Message: </h4>
                <h4 >{viewdata.message}</h4>
                
              </span> */}
            
            </Stack>
          </Stack>
        </Dialog>
        {/* End dialog box to add data */}
      </Box>
      {/* Content End */}
    </Box>
  );
}
export default Contactus;
