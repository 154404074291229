import React, { useEffect, useState } from 'react'
import Movingcarousel from '../../Components/Carousel/Carousel';
import "./Home.scss"
import Navigationbar from '../../Components/Navbar/Navbar'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Footer from '../../Components/Footer/Footer';
import { getDocs, query, limit, addDoc } from 'firebase/firestore'
import { companyoverviewref, contactusref, galleryref, popupref } from '../../../Firebase/Firebase';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Form, Col, Row, Button } from "react-bootstrap";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import swal from "sweetalert";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "80%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
  maxHeight: "80vh",


};

const smstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "90%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: "scroll",
  maxHeight: "80vh",
  p: 2,

};




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}
const Home = () => {
  const [todaydate, setTodaydate] = useState(getDate())
  const [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    date: todaydate,
    status: "pending"
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleClose = (id) => {
    const newList = popupdata.filter((item => item.id !== id))
    setPopupdata(newList)


  }
  // Get about company from firebase database

  const [data, setData] = useState([])
  const [popupdata, setPopupdata] = useState([])
  const [gallerydata, setGallerydata] = useState([])

  useEffect(() => {
    async function getData() {
      const _data = await getDocs(companyoverviewref)
      const _popupdata = await getDocs(popupref)
      _data.forEach((element) => {
        setData((prv) => [...prv, { data: element.data(), id: element.id }])
      });
      _popupdata.forEach((element) => {
        setPopupdata((prv) => [...prv, { data: element.data(), id: element.id }])
      });

      if (popupdata) {
        handleOpen()

      }




    }
    async function getGallerydata() {
      const _data = await getDocs(query(galleryref, limit(4)))
      _data.forEach((element) => {
        setGallerydata((prv) => [...prv, { data: element.data(), id: element.id }])
      });


    }


    getData()
    getGallerydata()



  }, []);

  // Get about company from firebase end

  // Contact Form

  const Handlesubmit = (e) => {
    e.preventDefault()
    try {
      if (form.name !== "" && form.email !== "" && form.message !== "") {
        if (/[!@#$%^/&*(),.?":{}|<>]/g.test(form.name) || /[!#$%^&*(),?":{}|<>]/g.test(form.email) || /[!$;{}|>]/g.test(form.message) || /[!@#$%^&*(),.?":{}|<>]/g.test(form.phone) || /^[A-Z]/.test(form.phone) || /^[a-z]/.test(form.phone)) {
          swal({
            title: "Please Check all the fields ",
            icon: "error",
            button: false,
            timer: 3000,
          });
          return

        } else {
          try {
            addDoc(contactusref, form);
            swal({
              title: "Message Sent",
              icon: "success",
              button: false,
              timer: 3000,
            });
          } catch (err) {
            swal({
              title: err,
              icon: "Error",
              button: false,
              timer: 3000,
            });
          }

        }


        setForm({
          name: "",
          phone: "",
          email: "",
          message: "",
        });
      } else {
        swal({
          title: "Please fill all the fields",
          icon: "error",
          button: false,
          timer: 3000,
        });
      }

    } catch (error) {
      alert(error);
    }

  }

  // Contact Form End

  return (
    <>

      <div className='fullscroll fullscrollehome'>

        <div className='Section'>
          <Navigationbar />

          <Movingcarousel />

        </div>
        <div className='Section'>
          <div className='text-container'>

            <h1>Company Overview</h1>
            {data.map((e) => (
              <p dangerouslySetInnerHTML={{ __html: e.data.Companyoverview }}></p>
            ))}
            <a href='about-us'>

              <button class="button"> Read More
              </button>
            </a>
          </div>
        </div>
        <div className='Section imageview'>
          <div>
            <h1>Images</h1>

            <ImageList sx={{ width: 1000, height: 250 }}  cols={4} rowHeight={125}>
              {


                gallerydata.sort((a,b)=>a.data.date < b.data.date ? 1 : -1).map((e, i) => (

                  <ImageListItem key={i} sx={{ height: 250, overflow: "hidden" }}>
                    <img
                      src={`${e.data.Image}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${e.data.Image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={e.Title}
                      loading="lazy"


                    />
                  </ImageListItem>


                ))
              }
            </ImageList>


            <a href='gallery'>

              <button class="button" >Gallery >>
              </button>
            </a>
          </div>
        </div>


        <div className='Section'>
          <div className="map">
            <div className='center'>


            </div>
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1765.6382298756334!2d85.31991792908372!3d27.739617692841442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19cbc22cf633%3A0x6fec8b88900b24a5!2sShikhar%20Power%20Development%20Pvt.%20Ltd.!5e0!3m2!1sne!2snp!4v1602847757288!5m2!1sne!2snp"
              className='mapdata'
            ></iframe> */}
            <Box>
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                <Grid item xs={6} md={6} sm={12}>
                  <Item>
                    <h1>Find Us</h1>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1765.6382298756334!2d85.31991792908372!3d27.739617692841442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19cbc22cf633%3A0x6fec8b88900b24a5!2sShikhar%20Power%20Development%20Pvt.%20Ltd.!5e0!3m2!1sne!2snp!4v1602847757288!5m2!1sne!2snp"
                      className='mapdata'
                    ></iframe></Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <h1>Contact Us</h1>
                    <Form style={{ width: '100%', flex: '1' }} >
                      <Form.Group size="sm" as={Row} controlId="formPlaintextFname">
                        <Form.Label column sm="3">
                          <h5>

                            Name:
                          </h5>
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="text" placeholder="Your Name"
                          onChange={(e) => setForm({ ...form, name: e.target.value })}
                          value={form.name}

                          />
                        </Col>
                      </Form.Group>
                      <Form.Group size="sm" as={Row} controlId="formPlaintextPnum">
                        <Form.Label column sm="3">
                          <h5>

                            Phone
                          </h5>
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="number" placeholder="98********"
                          onChange={(e) => setForm({ ...form, phone: e.target.value })}
                          value={form.phone}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group size="sm" as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="3">
                          <h5>

                            Email:
                          </h5>
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="email" placeholder="Your Mail Address"
                          onChange={(e) => setForm({ ...form, email: e.target.value })}
                          value={form.email}


                          />
                        </Col>
                      </Form.Group>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label column sm="3">
                          <h5>Message:</h5></Form.Label>
                        <Form.Control as="textarea" rows="15" 
                          onChange={(e) => setForm({ ...form, message: e.target.value })}
                          value={form.message}
                        
                        />

                      </Form.Group>
                      <Button variant="primary" onClick={Handlesubmit} >
                        Submit
                      </Button>

                    </Form>

                  </Item>
                </Grid>

              </Grid>
            </Box>

          </div>


        </div>
        <div className='Section footerfullscreeen'>
          <Footer />
        </div>


      </div>
      {
        popupdata.map((e, i) => (

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => handleClose(e.id)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            key={i}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div className='closebutton'>
                  <CloseIcon className='closeicon' onClick={() => handleClose(e.id)}
                  />
                </div>

                <Typography id="transition-modal-title" variant="h3" component="h2">
                  {e.data.title}
                </Typography>
                <img
                  src={e.data.file}
                  alt="First slide"
                  style={{ maxWidth: "100%" }}
                />

              </Box>
            </Fade>
          </Modal>

        ))
      }

    </>
  )
}


export default Home
