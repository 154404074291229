import React, { useState, useEffect } from "react";
import Navigationbar from "../../Components/Navbar/Navbar";
import { Image } from "react-bootstrap";
import { getDocs, query, limit } from "firebase/firestore";
import { projectref } from "../../../Firebase/Firebase";
import { DataGrid } from "@mui/x-data-grid";
import "./firstproject.scss";
import Footer from "../../Components/Footer/Footer";

const Firstproject = () => {
  // Get about company from firebase database

  const [data, setData] = useState([]);
  const [details, setDetails] = useState(false);

  useEffect(() => {
    async function getData() {
      const _data = await getDocs(projectref);
      _data.forEach((element) => {
        setData((prv) => [...prv, { data: element.data(), id: element.id }]);
      });
    }
    getData();
  }, []);
  // Get about company from firebase end
  const showhide = () => {
    if (details == "true") {
      setDetails(false);
    } else {
      setDetails(true);
    }
  };
  return (
    <>
      <div className="fullscroll firstproject">
        <div className="Section">
          <Navigationbar />

          <div className="center">
            {data.slice(0, 1).map((e, i) => (
              <>
                <div className="title">
                  <h4>{data[0].data.title}</h4>
                </div>
                <div className="imagebox">
                  <div className="full-image">
                    <Image
                      className="image"
                      thumbnail
                      src={data[0].data.image}
                    />
                  </div>
                  <button onClick={showhide} class="learn-more">
                    <span class="circle" aria-hidden="true">
                      <span class="icon arrow"></span>
                    </span>
                    <span class="button-text">Learn More</span>
                  </button>
                  {details && (
                    <div className="description">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data[0].data.projectdes,
                        }}
                      ></div>
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="Section footerfullscreeen">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Firstproject;
