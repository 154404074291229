
import Carousel from './Pages/Carousel'
import Companyoverview from './Pages/Companyoverview';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import Homegallery from './Pages/Homegallery';
import Aboutcompany from './Pages/Aboutcompany';
import Visionandmission from './Pages/Visionandmission';
import Boardofdirector from './Pages/Boardofdirector'
import Managementteam from './Pages/Managementteam'
import Project from './Pages/Project'
import Gallery from './Pages/Gallery'
import News from './Pages/News'
import Report from './Pages/Annualreport'
import Contactus from './Pages/Contactus'
import Main from './Pages/mainfile'
import Quaterlyreport from './Pages/QuaterlyReports';
import Popup from './Pages/Popup';
import Downloads from './Pages/Downloads';
import Organizationchart from './Pages/Organizationchart';
import Messagefrom from './Pages/Messagefrom';


const Admin = () => {
  return (
    <Router>
      <Routes>
      <Route exact path='/admin' element={<Main/>}/>
      <Route exact path='/admin/visionmission' element={<Visionandmission/> } />
      <Route exact path='/admin/boardofdirector' element={<Boardofdirector/> } />
      <Route exact path='/admin/managementteam' element={<Managementteam/> } />
      <Route exact path='/admin/project' element={<Project/> } />
      <Route exact path='/admin/gallery' element={<Gallery/> } />
      <Route exact path='/admin/news' element={<News/> } />
      <Route exact path='/admin/annualreport' element={<Report/> } />
      <Route exact path='/admin/contactus' element={<Contactus/> } />
      <Route exact path='/admin/about' element={<Aboutcompany/> } />
      <Route exact path='/admin/messagefrom' element={<Messagefrom/> } />
      <Route exact path='/admin/homegallery' element={<Homegallery/> } />
      <Route exact path='/admin/carousel' element={<Carousel/> } />
      <Route exact path='/admin/companyoverview' element={<Companyoverview/> } />
      <Route exact path='/admin/quaterlyreport' element={<Quaterlyreport/> } />
      <Route exact path='/admin/downloads' element={<Downloads/> } />
      <Route exact path='/admin/popups' element={<Popup/> } />
      <Route exact path='/admin/organizationchart' element={<Organizationchart/> } />
      </Routes>
    </Router>
  )
}

export default Admin