import React,{useState} from 'react'
import Navigationbar from '../../Components/Navbar/Navbar'
import './Bod.scss'
import Footer from '../../Components/Footer/Footer'
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { boardofdirectorref } from '../../../Firebase/Firebase';
import { getDocs} from 'firebase/firestore'
import { useEffect } from 'react'




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const Bod = () => {
  // Get data from firebase
  const [data, setData] = useState([])
  useEffect(() => {
    async function getData() {
      const _data = await getDocs(boardofdirectorref)
      _data.forEach((element) => {
        setData((prv) => [...prv, { data: element.data(), id: element.id }])
      });


    }
  


    getData()
    



  }, []);

// Get data from firebase end
  return (
    <>
      <div className='fullscroll bodfullscroll'>

        <div className='Section'>
          <Navigationbar />
          <div className='center'>

          <div className="topic title">
            <h2>Board of Directors</h2>
          </div>
          </div>
          <div className='data_container'>
            
            <div className='data'>
              <Box sx={{ flexGrow: 1 }} >
                <Grid className='bodcoll' container spacing={{ xs: 2, md: 5 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {
                    data.sort((a,b)=>a.data.index > b.data.index ? 1 : -1).map((e,i)=>(
                      <Grid xs={2} sm={4} md={4} key={i} className='bodcollection'>
                      
                      <div class="flip-card">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <img src={e.data.image}/>
                            <p class="title">{e.data.fullname}</p>
                            <p>{e.data.designation}</p>
                          </div>
                          <div class="flip-card-back">
                            {/* <p class="title">BACK</p> */}
                            <span dangerouslySetInnerHTML={{__html: e.data.fpposition}}/>
                          </div>
                        </div>
                      </div>
                      
                    </Grid>

                    ))
                  }
                    
              
                </Grid>
              </Box>
            </div>
          </div>


        </div>


        <div className='Section footerfullscreeen'>
          <Footer />
        </div>
      </div></>
  )
}

export default Bod
