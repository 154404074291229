// import React from 'react'

// const Report = () => {
//   return (
//     <div>
//       Reports
//     </div>
//   )
// }

// export default Report



import React, { useState, setState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { addDoc, getDocs, doc, deleteDoc } from "firebase/firestore";
import {useNavigate } from "react-router-dom";
import { auth } from "../../Firebase/Firebase";
import { signOut } from "firebase/auth";

import swal from "sweetalert";
import {
  getStorage,
  uploadBytes,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from "firebase/storage";
import { quaterlyreportsref } from "../../Firebase/Firebase";
import Navmenu from "../Components/Navmenu"
const navdata = Navmenu()

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// For Dialog Box to add Data

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// End ffor dialog box to add data


// Current Date


function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}

// Current Date END

function Quaterlyreport() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [todaydate, setTodaydate] = useState(getDate())
  const [disabled, setDisabled] = useState(false)


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //   For Dialog Box to add Data
  const [openform, setOpenform] = React.useState(false);

  const handleOpenform = () => {
    setOpenform(true);
  };

  const handleCloseform = () => {
    setOpenform(false);
  };

  // End dialog box to add data

  // Adding carousel Data to database started
  const [form, setForm] = useState({
    year:"",
    image: "",
    title: "",
    date:todaydate
  });
  const [quaterlyreportfile, SetQuaterlyreportfile] = useState(null);

  const addFile = async () => {
    setDisabled(!disabled)
    // Upload image firestore
    try {
      const QuaterlyReportsref = ref(getStorage(), "QuaterlyReports/" + new Date()+quaterlyreportfile.name);
      const upload = uploadBytesResumable(QuaterlyReportsref, quaterlyreportfile);
      await upload.on(
        "state_changed",
        (snapshot) => {
          const progess = snapshot.bytesTransferred / snapshot.totalBytes;
        },
        (err) => {
          alert(err);
          setDisabled(false)
        },
        () => {
          getDownloadURL(upload.snapshot.ref).then((downloadurl) => {
            const newUploadData = {
              year:form.year,
              title: form.title,
              file: downloadurl,
              date:form.date
            };
            if (newUploadData.file !== "" && newUploadData.title !== "") {
              
              try {
                addDoc(quaterlyreportsref, newUploadData);
                swal({
                  title: "Successfully Report Added",
                  icon: "success",
                  button: false,
                  timer: 3000,
                });
              } catch (err) {
                swal({
                  title: err,
                  icon: "Success",
                  button: false,
                  timer: 3000,
                });
              }
              setOpenform(false);
              setForm({
                image: "",
                title: "",
              });
            } else {
              swal({
                title: "Please fill all the fields",
                icon: "error",
                button: false,
                timer: 3000,
              });
            }
            if (state == true) {
              setState(false);
            } else {
              setState(true);
            }

            setData([]);
            SetQuaterlyreportfile(null)
            setDisabled(false)
          });
        }
      );
    } catch (err) {
      alert(err);
      setDisabled(false)
    }
    
  };
  // Adding data to database end

  // Get Quaterly Report data from firebase database

  const [data, setData] = useState([]);
  const [state, setState] = useState(false);

  useEffect(()=>{
      async function getData(){
          const _data = await getDocs(quaterlyreportsref)
         _data.forEach((element) => {
          setData((prv)=>[...prv,{data:element.data(), id:element.id}])

         });

      }
      getData()

  },[state]);

  // Get Quaterly Report data from firebase end

  // Quaterly Report Delete Start
  async function deleteQuaterlyreportdata(props) {
    const storage = getStorage();
    const desertRef = ref(storage, props[1]);


    try {
        if (desertRef._location.path) {
            await deleteObject(desertRef)
            
        }
       
      await deleteDoc(doc(quaterlyreportsref, props[0]));
      swal({
        title: "Report Deleted",
        icon: "success",
        button: false,
        timer: 3000,
      });
    } catch (e) {
      swal({
        title: e,
        icon: "error",
        button: false,
        timer: 3000,
      });
    }
    if (state == true) {
      setState(false);
    } else {
      setState(true);
    }
    setData([]);
    SetQuaterlyreportfile(null)
  }

  // Quaterly Report Delete End

  // Logout function
const navigate = useNavigate();
function logout() {
  signOut(auth)
    .then((res) => {
      navigate("/login");
    })
    .catch((err) => console.log(err));
}
// Logout end

  

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Quaterly Reports
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" noWrap component="div">
            Shikhar Power
          </Typography>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navdata.map((text, index) => (
            <Link to={text.link} className="menubuttonlink">
              <ListItem
                key={text.name}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  className="menubutton"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemText
                    primary={text.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
          <ListItem
            disablePadding
            sx={{ display: "block", background: "red", color: "#fff" }}
            onClick={logout}
          >
            <ListItemButton
              className="menubutton"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      {/* Content Start */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Button variant="contained" sx={{ mb: 3 }} onClick={handleOpenform}>
          ADD
        </Button>

        {/* Data Grid Started 
        here we will show Data
        */}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Year</TableCell>
                <TableCell>Title</TableCell>
                <TableCell align="right">File</TableCell>
                <TableCell align="right">Created Date</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data.sort((a,b)=>a.data.date < b.data.date ? 1 : -1).map((e,i)=>(
                  <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {e.data.year}
                </TableCell>
                <TableCell component="th" scope="row">
                  {e.data.title}
                </TableCell>
                <TableCell align="right">{e.data.file}</TableCell>
                <TableCell align="right">{e.data.date}</TableCell>
                <TableCell align="right">
                  <Button variant="text" onClick={()=>deleteQuaterlyreportdata([e.id,e.data.file])}>
                    <DeleteIcon />
                  </Button>
                  {/* <Button>
                    <EditIcon />
                  </Button> */}
                </TableCell>
              </TableRow>

                ))
              }
              


            </TableBody>
          </Table>
        </TableContainer>

        {/* End Table Data */}

        {/* For Dialog box to add data */}
        <Dialog
          fullScreen
          open={openform}
          onClose={handleCloseform}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar sx={{ pl: 2, flex: 1 }}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add Report
              </Typography>
            </Toolbar>
          </AppBar>
          <Stack
            direction="Column"
            justifyContent="center"
            alignItems="center"
            marginTop={10}
            spacing={5}
          >
            <Stack width={500} spacing={2}>
            <TextField
                fullWidth
                id=" year"
                label="year"
                variant="outlined"
                onChange={(e) => setForm({ ...form, year: e.target.value })}
              />
              <TextField
                fullWidth
                id=" title"
                label="Title"
                variant="outlined"
                onChange={(e) => setForm({ ...form, title: e.target.value })}
              />
              <TextField
                fullWidth
                type="file"
                id="image"
                border="none"  
                onChange={(e) => SetQuaterlyreportfile(e.target.files[0])}
              />
              <Stack spacing={2}>
                {
                  disabled?
                  <Button variant="contained" size="large" onClick={addFile} disabled>
                  SAVE
                </Button>:
                <Button variant="contained" size="large" onClick={addFile}>
                SAVE
              </Button>
                }
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleCloseform}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Dialog>
        {/* End dialog box to add data */}
      </Box>
      {/* Content End */}
    </Box>
  );
}
export default Quaterlyreport;

