import React, { useEffect, useState } from 'react'
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallery.scss'
import Navigationbar from '../../Components/Navbar/Navbar'
import { getDocs } from 'firebase/firestore';
import { galleryref } from '../../../Firebase/Firebase';
import Footer from '../../Components/Footer/Footer';



export default function Gallery() {
  const [gallerydata, setGallerydata] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    
    async function getGallerydata() {
      const _data = await getDocs(galleryref)
      _data.forEach((element) => {
        setGallerydata((prv) => [...prv, { data: element.data(), id: element.id }])
        setLoading(true)
      });


    }


   
   
    getGallerydata()




  }, []);
  
  return (
    
    <div id='gallerymain'>
      <Navigationbar/>
        {/* <Header name="Gallery"/> */}
        <div className='center'>

          <div className="topic title">
            <h2>Gallery</h2>
          </div>
          </div>
        <div className='galleryview'>

        {loading && (
          <SlideshowLightbox className="gallery" >

      
        {gallerydata.sort((a,b)=>a.data.date < b.data.date ? 1 : -1).map((e,i) => (
          
          <img
          src={`${e.data.Image}`}
          srcSet={`${e.data.Image}`}
          alt={e.data.title}
          loading="lazy"
          key={i}
          />
          
          ))}
       

        
      
      </SlideshowLightbox>
      
      )}
      </div>
      <Footer/>

      </div>
  );
}

