import React, { useState } from 'react'
import Navigationbar from '../../Components/Navbar/Navbar'
import { Form, Col, Row, Button } from "react-bootstrap";
import "./contactus.scss"
import Footer from '../../Components/Footer/Footer';
import { contactusref } from '../../../Firebase/Firebase';
import { addDoc } from 'firebase/firestore';
import swal from "sweetalert";



function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}

const Contactus = () => {
  const [todaydate, setTodaydate] = useState(getDate())
  const [form, setForm] = useState({
    name: "",
    phone:"",
    email: "",
    message:"",
    date:todaydate,
    status:"pending"
  });

const Handlesubmit =(e) =>{
  e.preventDefault()
try {
  if (form.name !== "" && form.email !== "" && form.message !== "" ) {
   if (/[!@#$%^/&*(),.?":{}|<>]/g.test(form.name) || /[!#$%^&*(),?":{}|<>]/g.test(form.email) || /[!$;{}|>]/g.test(form.message) || /[!@#$%^&*(),.?":{}|<>]/g.test(form.phone) || /^[A-Z]/.test(form.phone) || /^[a-z]/.test(form.phone)  ) {
    swal({
      title: "Please Check all the fields ",
      icon: "error",
      button: false,
      timer: 3000,
    });
    return
    
   } else {
    try {
      addDoc(contactusref, form);
      swal({
        title: "Message Sent",
        icon: "success",
        button: false,
        timer: 3000,
      });
    } catch (err) {
      swal({
        title: err,
        icon: "Error",
        button: false,
        timer: 3000,
      });
    }
    
   }
    
   
    setForm({
    name: "",
    phone:"",
    email: "",
    message:"",
    });
  } else {
    swal({
      title: "Please fill all the fields",
      icon: "error",
      button: false,
      timer: 3000,
    });
  }
  
} catch (error) {
  alert(error);
}

}


  return (
    <>
    <div className='fullscroll'>
    
    <div >
    <Navigationbar/>
    <div class="container">
    <form class="form">
      <div class="descr">Contact us</div>
      <div class="input">
          <input required="" autocomplete="off" type="text" value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })}/>
          <label for="name">Name</label>
      </div>
      <div class="input">
          <input required="" autocomplete="off" type="phone" value={form.phone} onChange={(e) => setForm({ ...form, phone: e.target.value })}/>
          <label for="name">Phone</label>
      </div>

      <div class="input">
          <input required="" autocomplete="off" name="email" type="email" value={form.email} onChange={(e) => setForm({ ...form, email: e.target.value })}/>
          <label for="email">E-mail</label>
      </div>

      <div class="input">
          <textarea required="" cols="20" rows="4" id="message" value={form.message} onChange={(e) => setForm({ ...form, message: e.target.value })}></textarea>
          <label for="message">Message</label>
      </div>
      <button onClick={Handlesubmit}>Send message →</button>
    </form>
</div>


   <Footer/>
    </div>
    </div></>
    )
}

export default Contactus
