import React, { useEffect, useState } from 'react'
import Navigationbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './Download.scss'
import { downloadref } from '../../../Firebase/Firebase';
import {getDocs} from "firebase/firestore";
import { Link } from 'react-router-dom';







const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const Downloads = () => {
  // Get Reports Start


  const [data, setData] = useState([]);

  useEffect(()=>{
      async function getData(){
          const _data = await getDocs(downloadref)
         _data.forEach((element) => {
          setData((prv)=>[...prv,{data:element.data(), id:element.id}])

         });

      }
      getData()

  },[]);


  // Get reports en
  
  return (
    <>
    <div >
    
    <div className='Section'>
    <Navigationbar/>
    <div className='center downloadcontainer'>
    <div className="title">
            <h3>Downloads</h3>
          </div>
          <div>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                {data.sort((a,b)=>a.data.date < b.data.date ? 1 : -1).map((e, i) => (

                    <div className='downloadlist' key={i}>
                        <ul>
                            <Link to={e.data.file}>
                            <li>
                            <h4>{e.data.title}</h4>
                            </li>
                            </Link>
                        </ul>
                    </div>
                //   <Grid item xs={12} sm={12} md={12} key={i}>
                    
                 
                       
                //           <Item >
                //           <h5>{e.data.year}</h5>
                //           <h5>{e.data.title}</h5>
                //           {/* <p>hello this is news </p> */}
                //           <button className="button"> <Link to={e.data.file}>View Report</Link>
                //           </button>
                //         </Item>
                          
                       
                    
                //   </Grid>
                ))}
              </Grid>
            </Box>
          </div>
        </div>

   
    </div>
    <div className='Section footerfullscreeen'>
          <Footer />
        </div>
    </div></>
  )
}

export default Downloads
