import { getFirestore, collection,doc } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGEING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const auth = getAuth(app)
export const carosealref = collection(db, "carousel")
// For Carousel image 
export const storage = getStorage(app)
export const carouselstorageref = ref(storage, "carouselimage/")
// End for carousel image
// For CompanyOverview
export const companyoverviewref = collection(db, "CompanyOverview")
//  For companyoverview End
// for about company
export const aboutcompanyref = collection(db, "Aboutcompany")
// for about company end
// for vision and mission
export const visionandmissionref = collection(db, "Visionandmission")
// for vision and mission end
// for board of director
export const boardofdirectorref = collection(db, "Boardofdirector")
// for board of director end
// for projects
export const projectref = collection(db,"project")
// for project end
// for message from
export const messagefromref= collection(db,"Messagefrom")
// for message from end
// for management team
export const managementteamref= collection(db,"managementteam")
// for management team end
// for Gallery
export const galleryref= collection(db,"Gallery")
// for Gallery end
// for News
export const newsref= collection(db,"News")
// for News end
// for reports
export const reportsref= collection(db,"Reports")
// for reports end
// for quaterlyreports
export const quaterlyreportsref= collection(db,"QuaterlyReports")
// for quaterlyreports end
// for Popup
export const popupref= collection(db,"Popup")
// for Popup end
// for Popup
export const downloadref= collection(db,"Downloads")
// for Popup end
// for Organization Chart
export const organizationchartref= collection(db,"OrganizationChart")
// for Organization Cart end
// for Contactus
export const contactusref= collection(db,"ContactUs")
// for Contactus end
