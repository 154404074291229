import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";
import { managementteamref } from "../../Firebase/Firebase";
import {useNavigate } from "react-router-dom";
import { auth } from "../../Firebase/Firebase";
import { signOut } from "firebase/auth";
import {
  getStorage,
  uploadBytes,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { addDoc, getDocs, doc, deleteDoc, updateDoc } from "firebase/firestore";
import swal from "sweetalert";
import Avatar from "@mui/material/Avatar";
import Navmenu from "../Components/Navmenu"
const navdata = Navmenu()

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// For Dialog Box to add Data

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// End ffor dialog box to add data

function Managementteam() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [previndex, setPrevindex] = useState("")
  const [disabled, setDisabled] = useState(false)


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //   For Dialog Box to add Data
  const [openform, setOpenform] = React.useState(false);

  const handleOpenform = () => {
    setIsupdating(false);
    setOpenform(true);
    const numbers = [...data.map((d)=>d.data.index)];
    const maxValue = numbers.reduce((max, current) => {
      return current > max ? current : max;
    }, numbers[0])
    
    if (maxValue>0) {
      setForm({ ...form, index: Number(maxValue)+1 })
    }else{
      setForm({ ...form, index: 1 })
    
    }
  };

  const handleCloseform = () => {
    setOpenform(false);
    setForm({
      image: "",
      fullname: "",
      phone: "",
      email: "",
      designation: "",
      index: "",
    });
  };

  // End dialog box to add data

  // Editor Sarted
  const editor = useRef(null);

  // editor ended

  // Adding carousel Data to database started
  const [form, setForm] = useState({
    image: "",
    fullname: "",
    phone: "",
    email: "",
    designation: "",
    index: "",

  });
  const [managementteamimage, setManagementteamimage] = useState(null);
  const [state, setState] = useState(false);

  const addData = async () => {
    setDisabled(true)
    
    // Upload image firestore
    try {

      const numbers = data.map(d => d.data.index);
      const maxValue = Math.max(...numbers); // Find the maximum value using Math.max
      
      if (maxValue >= form.index) {
        
        const loopStart = form.index;
      
        for (let i = loopStart; i <= maxValue; i++) {
          const updatedata=data.filter(d=>d.data.index == i)
          
            await updateDoc(doc(managementteamref, updatedata[0].id), {
              index: Number(i)+1,
            })
         

          
        }
      }
    


      const managementteamimgref = ref(getStorage(), "managementteam/" + new Date()+managementteamimage.name);
      const upload = uploadBytesResumable(managementteamimgref, managementteamimage);
      upload.on(
        "state_changed",
        (snapshot) => {
          const progess = snapshot.bytesTransferred / snapshot.totalBytes;
        },
        (err) => {
          alert(err);
          setDisabled(false)
        },
        () => {
          getDownloadURL(upload.snapshot.ref).then((downloadurl) => {
            const newUploadData = {
              image: downloadurl,
              fullname: form.fullname,
              phone: form.phone,
              email: form.email,
              designation: form.designation,
              index: form.index

            };
            if (
              newUploadData.image !== "" &&
              newUploadData.fullname !== "" &&
              newUploadData.phone !== "" &&
              newUploadData.email !== "" &&
              newUploadData.designation !== "" &&
              newUploadData.index !== ""
            ) {
              try {
                addDoc(managementteamref, newUploadData);
                swal({
                  title: "Successfully Management Team Added",
                  icon: "success",
                  button: false,
                  timer: 3000,
                });
              } catch (err) {
                swal({
                  title: err,
                  icon: "Success",
                  button: false,
                  timer: 3000,
                });
              }
              setOpenform(false);
              setForm({
                image: "",
                title: "",
                description: "",
              });
            } else {
              swal({
                title: "Please fill all the fields",
                icon: "error",
                button: false,
                timer: 3000,
              });
            }
            if (state == true) {
              setState(false);
            } else {
              setState(true);
            }

            setData([]);
            setManagementteamimage(null)
            setDisabled(false)
          });
        }
      );
    } catch (err) {
      alert(err);
      setDisabled(false)
    }
    
  };
  // Adding data to database end

  // Get Carousel data from firebase database

  const [data, setData] = useState([]); 

  useEffect(() => {
    async function getData() {
      const _data = await getDocs(managementteamref);
      _data.forEach((element) => {
        setData((prv) => [...prv, { data: element.data(), id: element.id }]);
      });
    }
    getData();
    
    
  }, [state]);


  
  

  // Get carousel data from firebase end
  // Delete data from firebase

  async function deleteData(props) {
    setDisabled(!disabled)
    const storage = getStorage();
    const desertRef = ref(storage, props[1]);
    try {
      // UPDATE iNDEX
      const numbers = data.map(d => d.data.index);
      const maxValue = Math.max(...numbers); // Find the maximum value using Math.max
      
      if (maxValue > props[2]) {
        
        const loopStart = props[2];
      
        for (let i = loopStart; i <= maxValue; i++) {
          const updatedata=data.filter(d=>d.data.index == i)
          
            await updateDoc(doc(managementteamref, updatedata[0].id), {
              index: Number(i)-1,
            })
         

          
        }
      }
      // UPDATE INDEX END
      await deleteObject(desertRef);
      await deleteDoc(doc(managementteamref, props[0]));
      swal({
        title: "Management Team Deleted",
        icon: "success",
        button: false,
        timer: 3000,
      });
    } catch (e) {
      swal({
        title: e,
        icon: "error",
        button: false,
        timer: 3000,
      });
    }
    if (state == true) {
      setState(false);
    } else {
      setState(true);
    }
    setData([]);
    setManagementteamimage(null)
    setDisabled(false)
  }

  // Delete data from carousel end
  // Update dataform for update started
  const [isupdating, setIsupdating] = useState(false);
  function updateDocument(data) {
    setOpenform(true);
    setIsupdating(true);
    setForm({
      image: data.image,
      fullname: data.fullname,
      phone: data.phone,
      email: data.email,
      designation: data.designation,
      id: data.id,
      url: data.url,
      index: data.index

    });
    setPrevindex(data.index)
  }
  // Update data form for update End
  // Data Update Started
  // async function updateData(data) {
  //   try {

  //     await updateDoc(doc(managementteamref, data.id), {
  //       image: data.image,
  //       fullname: form.fullname,
  //       phone: form.phone,
  //       email: form.email,
  //       designation: form.designation,
  //       fpposition: content
  //     })
  //     swal({
  //       title: "Data Updated",
  //       icon: "success",
  //       button: false,
  //       timer: 3000
  //     })

  //   } catch (err) {
  //     swal({
  //       title: err,
  //       icon: "error",
  //       button: false,
  //       timer: 3000
  //     })
  //   }

  // }
  // Data update ended

  // Update Start

  const updateData = async (updata) => {
    setDisabled(!disabled)
if (managementteamimage==null) {
      try {
  
    
        
        

        // UPDATE iNDEX POSITION
      // const numbers = data.map(d => d.data.index);
      // console.log(numbers);
      
      const prevValue =  previndex
      const newValue =  form.index
      
      // FOR lOWER TO HIGHER POSITION 
      if (prevValue - newValue  > 0) {
        
        const loopStart = newValue;
      
        for (let i = loopStart; i < prevValue; i++) {
          const updatedata=data.filter(d=>d.data.index == i)
          
          
            await updateDoc(doc(managementteamref, updatedata[0].id), {
              index: Number(i)+1,
            })
         

          
        }
      }
      // FOR lOWER TO HIGHER POSITION END
      // FOR HIGHER TO LOWER POSITION 
      if (prevValue - newValue  < 0) {
        
        const loopStart = prevValue;
      
        for (let i = loopStart; i <= newValue; i++) {
          const updatedata=data.filter(d=>d.data.index == i)
          
          
            await updateDoc(doc(managementteamref, updatedata[0].id), {
              index: Number(i)-1,
            })
         

          
        }
      }
      // FOR HIGHER TO LOWER POSITION END
      // UPDATE INDEX  END
      
        
        

       

      await updateDoc(doc(managementteamref, updata.id), {
        fullname: form.fullname,
        phone: form.phone,
        email: form.email,
        designation: form.designation,
        index: form.index,

      })
      swal({
        title: "Management Team Updated",
        icon: "success",
        button: false,
        timer: 3000
      })

    } catch (err) {
      swal({
        title: err,
        icon: "error",
        button: false,
        timer: 3000
      })
    }
} else {
  const storage = getStorage();
    const desertRef = ref(storage, data.image);

  try {
    if(desertRef._location.path){
    await deleteObject(desertRef)
    }
    const managementteamimgref = ref(getStorage(), "managementteam/" + new Date()+managementteamimage.name);

    const upload = uploadBytesResumable(managementteamimgref, managementteamimage);

    upload.on(
      "state_changed",

      (snapshot) => {
        const progess = snapshot.bytesTransferred / snapshot.totalBytes;
      },
      (err) => {
        alert(err);
      },
      () => {
        getDownloadURL(upload.snapshot.ref).then((downloadurl) => {
          const newUploadData = {
            image: downloadurl,
            fullname: form.fullname,
            phone: form.phone,
            email: form.email,
            designation: form.designation,
            index: form.index,

          };
          if (
            newUploadData.fullname !== "" &&
            newUploadData.phone !== "" &&
            newUploadData.email !== "" &&
            newUploadData.designation !== "" &&
            newUploadData.index !== ""
          ) {
            try {
              if (newUploadData.image == "") {
                updateDoc(doc(managementteamref, data.id), {
                  // image: downloadurl,
                  fullname: form.fullname,
                  phone: form.phone,
                  email: form.email,
                  designation: form.designation,
                  index: form.index

                });
              } else {
                updateDoc(doc(managementteamref, data.id), {
                  image: downloadurl,
                  fullname: form.fullname,
                  phone: form.phone,
                  email: form.email,
                  designation: form.designation,
                  index: form.index
                });
              }

              swal({
                title: "Successfully Management Team Updated",
                icon: "success",
                button: false,
                timer: 3000,
              });
            } catch (err) {
              swal({
                title: err,
                icon: "Success",
                button: false,
                timer: 3000,
              });
            }
            setOpenform(false);
            setForm({
              image: "",
              title: "",
              description: "",
            });
          } else {
            swal({
              title: "Please fill all the fields",
              icon: "error",
              button: false,
              timer: 3000,
            });
          }
          if (state == true) {
            setState(false);
          } else {
            setState(true);
          }

          setData([]);
          setManagementteamimage(null)
        });
      }
    );
  } catch (err) {
    alert(err);
  }
  
}
setDisabled(false)

  }
  // Update End


// Logout function
const navigate = useNavigate();
function logout() {
  signOut(auth)
    .then((res) => {
      navigate("/login");
    })
    .catch((err) => console.log(err));
}
// Logout end


  return (
    
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Management Team
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" noWrap component="div">
            Shikhar Power
          </Typography>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navdata.map((text, index) => (
            <Link to={text.link} className="menubuttonlink">
              <ListItem
                key={text.name}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  className="menubutton"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemText
                    primary={text.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
          <ListItem
            disablePadding
            sx={{ display: "block", background: "red", color: "#fff" }}
            onClick={logout}
          >
            <ListItemButton
              className="menubutton"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      {/* Content Start */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Button variant="contained" sx={{ mb: 3 }} onClick={handleOpenform}>
          ADD
        </Button>

        {/* Data Grid Started 
        here we will show Data
        */}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>INDEX</TableCell>
                <TableCell align="right">IMAGE</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Phone</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Designation</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.sort((a,b)=>a.data.index > b.data.index ? 1 : -1).map((data, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell >{data.data.index}</TableCell>

                  <TableCell component="th" scope="row" align="right">
                    <Avatar variant="square" sx={{ width: 100, height: 70 }}>
                      <img
                        src={data.data.image}
                        style={{ width: 100, height: 100 }}
                      />
                    </Avatar>
                  </TableCell>
                  <TableCell align="right">{data.data.fullname}</TableCell>
                  <TableCell align="right">{data.data.phone}</TableCell>
                  <TableCell align="right">{data.data.email}</TableCell>
                  <TableCell align="right">{data.data.designation}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="text"
                      onClick={() =>
                        updateDocument({
                          id: data.id,
                          url:data.data.image,
                          fullname: data.data.fullname,
                          phone: data.data.phone,
                          email: data.data.email,
                          designation: data.data.designation,
                          index: data.data.index,
                        })
                      }
                    >
                      <EditIcon />
                    </Button>
                    {
                      disabled?
                      <Button
                      variant="text"
                      onClick={() => deleteData([data.id, data.data.image,data.data.index])}
                      disabled
                    >
                      <DeleteIcon />
                    </Button>:
                    <Button
                    variant="text"
                    onClick={() => deleteData([data.id, data.data.image,data.data.index])}
                  >
                    <DeleteIcon />
                  </Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* End Table Data */}

        {/* For Dialog box to add data */}
        <Dialog
          fullScreen
          open={openform}
          onClose={handleCloseform}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar sx={{ pl: 2, flex: 1 }}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add Management Team
              </Typography>
            </Toolbar>
          </AppBar>
          <Stack
            direction="Column"
            justifyContent="center"
            alignItems="center"
            marginTop={10}
            spacing={5}
          >
            <Stack width={500} spacing={2}>
              <TextField
                fullWidth
                type="file"
                accept=".png, .jpg, .jpeg"
                id="image"
                value={form.image}
                border="none"
                onChange={(e) => setManagementteamimage(e.target.files[0])}
              />
              <TextField
                fullWidth
                id=" fullname"
                label="Full Name"
                variant="outlined"
                value={form.fullname}
                onChange={(e) => setForm({ ...form, fullname: e.target.value })}
              />
              <TextField
                fullWidth
                id=" designation"
                label="Designation"
                variant="outlined"
                value={form.designation}
                onChange={(e) =>
                  setForm({ ...form, designation: e.target.value })
                }
              />
              <TextField
                fullWidth
                id="phone"
                label="Phone"
                variant="outlined"
                value={form.phone}
                onChange={(e) => setForm({ ...form, phone: e.target.value })}
              />
              <TextField
                fullWidth
                id="email"
                type="email"
                label="Email"
                variant="outlined"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
              <TextField
                fullWidth
                id="index"
                type="text"
                label="index"
                variant="outlined"
                value={form.index}
                onChange={(e) => setForm({ ...form, index: e.target.value })}
              />
               
              {/* <JoditEditor
                ref={editor}
                value={content}
                tabIndex={1} // tabIndex of textarea
                onChange={(content) => setContent(content)}
              /> */}

              <Stack spacing={2}>
                {isupdating ? (
                  disabled?
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() =>
                      updateData({ id: form.id, image: form.url, index:form.index })
                    }
                    disabled
                  >
                    Update
                  </Button>:
                  <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    updateData({ id: form.id, image: form.url, index:form.index })
                  }
                >
                  Update
                </Button>
                ) : (
                  disabled?
                  <Button variant="contained" size="large" onClick={addData} disabled>
                    SAVE
                  </Button>:
                  <Button variant="contained" size="large" onClick={addData}>
                  SAVE
                </Button>
                )}
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleCloseform}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Dialog>
        {/* End dialog box to add data */}
      </Box>
      {/* Content End */}
    </Box>
  );
}
export default Managementteam;
