export default function Navmenu(){
    return(
        [
            { name: "Carousel", link: "/admin/carousel" },
            { name: "Company Overview", link: "/admin/companyoverview" },
            { name: "Home Gallery", link: "/admin/gallery" },
            { name: "About Company", link: "/admin/about" },
            { name: "Message From", link: "/admin/messagefrom" },
            { name: "Vision and Mission", link: "/admin/visionmission" },
            { name: "Board of Directors", link: "/admin/boardofdirector" },
            { name: "Management Team", link: "/admin/managementteam" },
            { name: "Organization Chart", link: "/admin/organizationchart" },
            { name: "Project", link: "/admin/project" },
            { name: "Gallery", link: "/admin/gallery" },
            { name: "News", link: "/admin/news" },
            { name: "Annual Report", link: "/admin/annualreport" },
            { name: "Quaterly Report", link: "/admin/quaterlyreport" },
            { name: "Downloads", link: "/admin/downloads" },
            { name: "Popups", link: "/admin/popups" },
            { name: "Contact Us", link: "/admin/contactus" },
          ]
    )
}